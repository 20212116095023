import React, { useEffect, useState, useCallback, useContext, useMemo } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import Calendar from 'components/Common/Calendar'
import { addMonths, endOfMonth, dateIsAfter, dateIsBefore, startOfMonth } from 'lib/datetime/dateUtils'
import { CALENDAR_GA_EVENT_ACTION, CALENDAR_GA_EVENT_LABEL } from 'constants/analytics'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import DatePickerHeader from './DatePickerHeader'
import useDidChange from 'hooks/useDidChange'
import UserAnalyticsActionContext from 'contexts/userAnalyticsActionContext'
import VerticalSpacer from '../Spacing/VerticalSpacer'

const CalendarWrapper = styled(VerticalSpacer)`
  position: relative;
`

interface Props {
  onDatesChange: (dates: { startDate: moment.Moment; endDate?: moment.Moment; }) => void;
  endDate?: moment.Moment;
  startDate?: moment.Moment;
  initDate?: moment.Moment;
  minDate?: Date;
  maxDate?: Date;
  className?: string;
  startLabel?: string;
  endLabel?: string;
}

function DateRangePicker(props :Props) {
  const {
    onDatesChange,
    startDate,
    endDate,
    initDate,
    minDate,
    maxDate,
    className,
    startLabel,
    endLabel,
  } = props

  const defaultFocusedDated = useMemo(() => {
    if (startDate) {
      return startDate.toDate()
    } else if (initDate && minDate && initDate.toDate() < minDate) {
      return minDate
    } else if (initDate) {
      return initDate.toDate()
    } else if (minDate) {
      return minDate
    } else {
      return new Date()
    }
  }, [startDate, initDate, minDate])

  const [date, setDate] = useState<Date>(defaultFocusedDated)

  const onUserAnalyticsAction = useContext(UserAnalyticsActionContext)

  const onDayClick = useCallback((day: moment.Moment) => {
    if (startDate && !endDate && day.isAfter(startDate)) {
      onDatesChange({ startDate, endDate: day })
      onUserAnalyticsAction?.(CALENDAR_GA_EVENT_ACTION.CheckOut, moment(day).format(ISO_DATE_FORMAT))
    } else {
      onDatesChange({ startDate: day, endDate: undefined })
      onUserAnalyticsAction?.(CALENDAR_GA_EVENT_ACTION.CheckIn, moment(day).format(ISO_DATE_FORMAT))
    }
  }, [onDatesChange, startDate, endDate, onUserAnalyticsAction])

  const onMonthChange = useCallback((direction: 'forwards' | 'backwards') => {
    if (direction === 'forwards') {
      setDate(addMonths(date, 1))
      onUserAnalyticsAction?.(CALENDAR_GA_EVENT_ACTION.ShiftMonth, CALENDAR_GA_EVENT_LABEL.NextMonth)
    } else {
      setDate(addMonths(date, -1))
      onUserAnalyticsAction?.(CALENDAR_GA_EVENT_ACTION.ShiftMonth, CALENDAR_GA_EVENT_LABEL.PreviousMonth)
    }
  }, [date, onUserAnalyticsAction])

  const startDateChanged = useDidChange(startDate)
  const minDateChanged = useDidChange(minDate)

  useEffect(() => {
    if (startDate && startDateChanged && startDate.month() !== date.getMonth()) {
      setDate(startDate.toDate())
    } else if (minDate && minDateChanged && minDate > date) {
      setDate(minDate)
    }
  }, [date, startDate, startDateChanged, minDate, minDateChanged])

  return (
    <CalendarWrapper gap={20} className={className}>
      <DatePickerHeader
        currentDate={date}
        onMonthChange={onMonthChange}
        disableBackwards={dateIsBefore(startOfMonth(date), minDate)}
        disableForwards={dateIsAfter(endOfMonth(date), maxDate)}
      />
      <Calendar
        month={date.getMonth()}
        year={date.getFullYear()}
        startDate={startDate?.toDate()}
        minDate={minDate}
        maxDate={maxDate}
        endDate={endDate?.toDate()}
        onDayClick={onDayClick}
        startLabel={startLabel}
        endLabel={endLabel}
      />
    </CalendarWrapper>
  )
}

export default React.memo(DateRangePicker)
